export const environmentCommon = {
  policyRu: 'https://policy.cookiereports.com/a2252c6a_panel-ru.js',
  policyAz: 'https://policy.cookiereports.com/a2252c6a_panel-az.js',
  premiumBenifitsRu: 'https://www.visapaycity.az/rus/premium-benifits',
  premiumBenifitsAz: 'https://www.visapaycity.az/az/premium-benifits',
  featuredTechnologiesRu: 'https://www.visa.com.az/ru_AZ/pay-with-visa/featured-technologies/visa-contactless.html',
  featuredTechnologiesAz: 'https://www.visa.com.az/az_AZ/pay-with-visa/featured-technologies/visa-contactless.html',
  visaFacebook: 'https://www.facebook.com/visaazerbaijan/',
  visaLinkedin: 'https://www.linkedin.com/company/visa',
  visaInsatgram: 'https://www.instagram.com/visaaz_official/',
  premiumCardPrivilegesAz: 'https://www.visa.com.az/az_AZ/pay-with-visa/find-a-card/visa-privileges.html',
  premiumCardPrivilegesRu: 'https://www.visa.com.az/ru_AZ/pay-with-visa/find-a-card/visa-privileges.html',
  visaconciergeChatBotAz: 'https://www.visa.com.az/az_AZ/pay-with-visa/promotions/chatbot.html',
  visaconciergeChatBotRu: 'https://www.visa.com.az/ru_AZ/pay-with-visa/promotions/chatbot.html',
  visaLegalProvisionsAz: 'https://www.visa.com.az/az_AZ/legal.html',
  visaLegalProvisionsRu: 'https://www.visa.com.az/ru_AZ/legal.html',
  termsAndConditionsAz: 'https://www.visa.com.az/az_AZ/supporting-info/tnc-visa-commercial-offers-platform.html#17_Platformada_v%C9%99_sosial_%C5%9F%C9%99b%C9%99k%C9%99l%C9%99rd%C9%99_n%C9%99%C5%9Frl%C9%99r-copy',
  termsAndConditionsRu: 'https://www.visa.com.az/ru_AZ/supporting-info/tnc-visa-commercial-offers-platform.html',
  privacyNoticeAz: 'https://www.visa.com.az/az_AZ/legal/global-privacy-notice.html',
  privacyNoticeRu: 'https://www.visa.com.az/ru_AZ/legal/global-privacy-notice.html',
  intellectualPropertyRrightsAz: 'https://www.visa.com.az/az_AZ/legal/intellectual-property-rights.html',
  intellectualPropertyRrightsRu: 'https://www.visa.com.az/ru_AZ/legal/intellectual-property-rights.html',
  cookieSettingsAz: 'https://policy.cookiereports.com/a2252c6a-az.html',
  cookieSettingsRu: 'https://policy.cookiereports.com/a2252c6a-ru.html',
  visaLoginRu: 'https://www.visa.com.az/ru_AZ/account/login?returnurl=%2Fru_az%2Fvisa-offers-and-perks%2F',
  visaLoginAz: 'https://www.visa.com.az/az_AZ/account/login?returnurl=%2Faz_AZ%2Fpay-with-visa%2Fpromotions%2Fpay-city.html',
  visaSignUpRu: 'https://www.visa.com.az/ru_AZ/account/registration?returnurl=%2Fru_AZ%2Fpay-with-visa%2Fpromotions%2Fpay-city.html',
  visaSignUpAz: 'https://www.visa.com.az/az_AZ/account/registration?returnurl=%2Faz_AZ%2Fpay-with-visa%2Fpromotions%2Fpay-city.html',
  accessBankRu: 'https://www.accessbank.az/ru/private/cards/mycard/?utm_source=VISA_Paycity&utm_medium=Building&utm_campaign=Mycards_page',
  accessBankAz: 'https://www.accessbank.az/az/private/cards/mycard/?utm_source=VISA_Paycity&utm_medium=Building&utm_campaign=Mycards_page',
};
