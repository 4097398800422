// Vendors
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppConfig } from './shared/config';
import { SitemapComponent } from './pages/sitemap/sitemap.component';
import { HeaderGuard, PageLeaveGuard } from './shared/guards';

const acceptedLanguages = AppConfig.acceptedLanguages;

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./containers/main/main.module').then((m) => m.MainModule),
    data: { title: 'Visa Map' },
  },
  {
    path: 'sitemap',
    component: SitemapComponent
  },
  {
    path: '**',
    loadChildren: () =>
      import('src/app/pages/error/error.module').then((m) => m.ErrorModule),
    data: { title: 'Error page', isShowBackBtn: false },
    canActivate: [HeaderGuard]
  },
];

acceptedLanguages.forEach(language => {
  routes.unshift(
    {
      path: language,
      loadChildren: () => import('./containers/main/main.module').then((m) => m.MainModule),
      data: { title: 'Visa Map' },
      canActivate: [HeaderGuard]

    }
  )
});

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: false }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
