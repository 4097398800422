import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { LeavePayCityPopupManager } from './leave-pay-city-popup.manager';
import { AppConfig } from '../config';

@Injectable({
	providedIn: 'root',
})
export class ClickManager {
	private renderer: Renderer2;
	private whiteListedDomains: string[] = AppConfig.whiteListedDomains;

	constructor(
		rendererFactory: RendererFactory2,
		private _leavePayCityPopupManager: LeavePayCityPopupManager
	) {
		this.renderer = rendererFactory.createRenderer(null, null);
		this.initClickInterceptor();
	}

	public initClickInterceptor(): void {
		this.renderer.listen('document', 'click', (event: MouseEvent) => {
			// Находим ближайший <a> от элемента, на котором произошёл клик
			const target = (event.target as HTMLElement).closest('a');

			if (target && target.hasAttribute('href')) {
				const href = target.getAttribute('href');
				const targetAttr = target.getAttribute('target');

				if (href) {
					// Если target="_blank", обрабатываем логику открытия в новом окне
					if (targetAttr === '_blank') {
						event.preventDefault();

						if (this._leavePayCityPopupManager.isInternalLink(href) || this._leavePayCityPopupManager.isWhiteListed(href)) {
							// Если ссылка на текущий сайт или white-listed, открываем без попапа
							window.open(href, '_blank');
						} else {
							// Иначе показываем попап
							this.safeOpen(href).catch((err) =>
								console.error('Ошибка обработки перехода:', err)
							);
						}
					}
				}
			}
		});
	}

	async safeOpen(url: string, target: string = '_blank', features: string = ''): Promise<void> {
		if (this._leavePayCityPopupManager.isWhiteListed(url)) {
			// Разрешённый URL, открываем напрямую
			window.open(url, target, features);
		} else {
			// Неразрешённый URL, показываем попап
			const confirm = await this._leavePayCityPopupManager.confirm(url);
			if (confirm) {
				window.open(url, target, features);
			}
		}
	}
}
