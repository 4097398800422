import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { AppConfig } from "../config";

@Injectable({ providedIn: 'root' })
export class LeavePayCityPopupManager {
	private _confirmSubject = new Subject<boolean>();
	public isShowSubj: Subject<boolean> = new Subject();
	public leaveUrl: string = '';

	// Список разрешённых доменов
	private whiteListedDomains: string[] = AppConfig.whiteListedDomains;

	constructor(
	) { }

	// Показываем попап и ждём ответа
	confirm(url: string): Promise<boolean> {

		// Проверяем, нужно ли показывать попап
		if (this.isInternalLink(url) || this.isWhiteListed(url)) {
			return Promise.resolve(true); // Пропускаем без попапа
		}

		this.leaveUrl = url; // Устанавливаем URL перехода
		this.isShowSubj.next(true); // Показываем попап

		return new Promise((resolve) => {
			this._confirmSubject.subscribe((result) => {
				this.isShowSubj.next(false); // Скрываем попап
				if (!result) {
					this.leaveUrl = null; // Сбрасываем URL, если пользователь остаётся
				}
				resolve(result);
			});
		});
	}

	// Устанавливаем результат
	setConfirmation(result: boolean) {
		this._confirmSubject.next(result);
	}

	// Проверка, является ли ссылка внутренней
	public isInternalLink(url: string): boolean {
		try {
			const parsedUrl = new URL(url, window.location.origin); // Разбираем URL относительно текущего сайта
			return parsedUrl.origin === window.location.origin; // Сравниваем домен ссылки с текущим доменом
		} catch {
			return false; // Если URL некорректный, считаем его не внутренним
		}
	}

	// Проверка, является ли ссылка white-listed
	public isWhiteListed(url: string): boolean {
		try {
			const parsedUrl = new URL(url); // Парсим URL
			const base = `${parsedUrl.protocol}//${parsedUrl.hostname}/`; // Базовый домен с завершающим слэшем

			// Сравниваем, начинается ли URL с одного из разрешённых доменов
			return this.whiteListedDomains.some((domain) => base.startsWith(domain));
		} catch {
			return false; // Если URL некорректный, считаем его неразрешённым
		}
	}
}
