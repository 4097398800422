import { Component, Renderer2 } from '@angular/core';
import { AnalyticManager, ClickManager, EngineToFrontManager } from './shared/managers';
import { WidgetModel } from './shared/models';
import { trigger, transition, style, animate } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0, scale: 0 }),
            animate('500ms ease-out',
              style({ opacity: 1, scale: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('1ms ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ],
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public widgets: WidgetModel[] = [];
  public isShowWidgets: boolean = false;

  constructor(
    private _analyticManager: AnalyticManager,
    private _engToFrontManger: EngineToFrontManager,
    private _translateService: TranslateService,
    private _clickManager: ClickManager,
    private renderer: Renderer2
  ) {
    this._analyticManager.initGMT();
    this._analyticManager.initDataLayer();

    this._translateService.use(environment.defaultLocale);

    this._engToFrontManger.isShowWidgetsSubj.subscribe((res) => {
      this.isShowWidgets = res;
    })

    this._engToFrontManger.isMapOpen.subscribe((res) => {
      this.isShowWidgets = res;
    })

    this._engToFrontManger.widgetsSubj.subscribe((res) => {
      this.widgets = res;
    })

    // this.widgets.push({
    //   x: 150,
    //   y: 150,
    //   maxDiscount: 10,
    //   minDiscount: 5,
    //   hasGifts: false,
    //   brandId: ''
    // })

    // setTimeout(() => {
    //   this.isShowWidgets = true
    // }, 2000)
  }

  public openBrandOffers(brandId: string): void {
    this._engToFrontManger.toggleBrandOffers(brandId);
  }
}
