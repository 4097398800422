import { ESortTypes } from '../enums';
import { CategoryModel } from '../models';
import { environment } from 'src/environments/environment';

export class AppConfig {
  static acceptedLanguages = ['az', 'rus'];
  static defaultLanguage = 'az';
  static currentLanguage = this.defaultLanguage;

  static emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,5}$/;

  static ruVisaSignUp: string = environment.visaSignUpRu;
  static azVisaSignUp: string = environment.visaSignUpAz;

  static whiteListedDomains = [
    'https://www.visa.com/',
    'https://visa.com/',
    'https://www.visa.com.az/',
    'https://visa.com.az/',
  ];

  static tutorial_name = 'pay_city_tutorial_counter';
  static max_tutorial_count = 3;
  static topOfferId: string = 'all-catalog-offers';
  static sorting = [
    {
      titleKey: 'a-z',
      type: ESortTypes['name'],
    },
    {
      titleKey: 'recently-added',
      type: ESortTypes['date'],
    },
    {
      titleKey: 'sort-by-discount',
      type: ESortTypes['discount'],
    },
    {
      titleKey: 'unique-promocodes',
      type: ESortTypes['specialOffers'],
    },
  ];

  static getAllCatalogOfferCategory(): CategoryModel {
    const category = new CategoryModel();
    category.id = this.topOfferId;
    category.pageIndex = 0;
    category.russianName = 'Все Предложения';
    category.azerbaijaniName = 'Bütün Təkliflər';
    category.blackImageUrl = 'assets/img/categories/prize-colored.png';
    category.whiteImageUrl = 'assets/img/categories/prize-white.png';

    return category;
  }

  static getAllOfferCategory(): CategoryModel {
    const category = new CategoryModel();
    category.id = this.topOfferId;
    category.pageIndex = 0;
    category.russianName = 'Все Предложения';
    category.azerbaijaniName = 'Bütün Təkliflər';
    category.blackImageUrl = 'assets/img/categories/prize-colored.png';
    category.whiteImageUrl = 'assets/img/categories/prize-blue.png';

    return category;
  }
}
