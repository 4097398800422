import { Injectable } from "@angular/core";
import { ReplaySubject, Subject } from "rxjs";
import { LeavePayCityPopupManager, OfferManager } from ".";
import { NavHelper } from "../helpers";
import { WidgetModel } from "../models";

@Injectable({ providedIn: 'root' })
export class EngineToFrontManager {
	private _isVideoOpen: boolean = false;
	public isShowLoaderSubj: Subject<boolean> = new Subject();
	public loaderProgressSubj: Subject<number> = new Subject();
	public selectedBrandSubj: Subject<string> = new Subject();
	public visaVideoStateSubj: Subject<boolean> = new Subject();
	public widgetsSubj: Subject<WidgetModel[]> = new Subject();
	public isShowWidgetsSubj: Subject<boolean> = new Subject();
	public isHideTutorial: Subject<boolean> = new Subject();
	public isMapOpen: ReplaySubject<boolean> = new ReplaySubject();

	constructor(
		private _offerManager: OfferManager,
		private _navHelper: NavHelper,
		private _leavePayCityPopupManager: LeavePayCityPopupManager,
	) { }

	public toggleMap(state: boolean): void {
		this.isMapOpen.next(state);
	}

	public hideTutorial(): void {
		this.isHideTutorial.next(false);
	}

	public toggleWidgets(state: boolean): void {
		this.isShowWidgetsSubj.next(state);
	}

	public async openLink(link: string): Promise<void> {
		const confirm = await this._leavePayCityPopupManager.confirm(link);
		if (confirm) {
			window.open(link, '_blank', 'noopener,noreferrer');
		}
	}

	public widgetsToShow(widgets: WidgetModel[]): void {
		this.widgetsSubj.next(widgets);
	}

	public toggleBrandOffers(brandId: string): void {
		this._navHelper.changeQueryParam(['brandId'], [brandId]);
		this._offerManager.selectBrand(brandId);
	}

	public toggleOffer(offerId: string): void {
		const currentUrl = this._navHelper.getCurrentUrl();

		if (currentUrl.includes('?')) {
			this._navHelper.replaceLocationState(`${currentUrl}&offerId=${offerId}`);
		} else {
			this._navHelper.replaceLocationState(`${currentUrl}?offerId=${offerId}`);
		}

		this._offerManager.selectOffer(offerId);
	}

	public setLoaderProgress(progress: number): void {
		this.loaderProgressSubj.next(progress);
	}

	public toggleLoader(state: boolean): void {
		this.isShowLoaderSubj.next(state);
	}

	public toggleVideo(): void {
		this.visaVideoStateSubj.next(!this._isVideoOpen);
		this._isVideoOpen = !this._isVideoOpen;
	}
}